<template>
  <div class="page-content" v-if="eventMission">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Event Mission" class="mb-3" :items="breadcrumbItems" />
      </b-col>
    </b-row>
    <section class="my-3">
      <h2 class="h2">Event Mission</h2>
      <b-card class="mt-1">
        <validation-observer ref="updateForm" v-slot="{ handleSubmit }" class="mr-4 ml-4">
          <b-form @submit.stop.prevent="handleSubmit()">
            <b-form-group label="Mission Name" label-for="h-mission-name" label-cols-md="3">
              <ui-component :component-data="eventMission.name" class="border" />
            </b-form-group>
            <b-form-group
              label="Mission Description"
              label-for="h-mission-description"
              label-cols-md="3"
            >
              <ui-component :component-data="eventMission.description" class="border" />
            </b-form-group>

            <validation-provider
              name="Mission key"
              v-slot="validationContext"
              :rules="{ required: true }"
            >
              <b-form-group label="Mission key" label-for="h-reward" label-cols-md="3">
                <b-form-input
                  v-model="eventMission.mission_key"
                  :state="getValidationState(validationContext)"
                  placeholder="watch_ads"
                />
                <b-form-invalid-feedback
                  id="input-category-name-feedback"
                >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              name="level"
              v-slot="validationContext"
              :rules="{ required: true, numeric:true }"
            >
              <b-form-group label="Level" label-for="h-reward" label-cols-md="3">
                <b-form-input
                  v-model="eventMission.level"
                  :state="getValidationState(validationContext)"
                  placeholder="10"
                />
                <b-form-invalid-feedback
                  id="input-category-name-feedback"
                >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
			<b-form-group label="Display on list" label-for="h-isShow" label-cols-md="3">
				<b-form-checkbox
					id="h-isShow"
					v-model="eventMission.is_show"
					class="mr-0 mt-50"
					name="is-rtl"
					inline
				/>
			</b-form-group>
            <template v-if="!isWatchAdsMission">
              <div class="mb-2">
                <h3>Rewards</h3>
              </div>
              <b-row v-for="(item, index) in eventMission.rewards" :key="index">
                <b-col sm="3">
                  <span>{{ getUserType(item.user_type) }}</span>
                </b-col>
                <b-col sm="9">
                  <b-form-group label="Reward Type" label-for="h-reward" label-cols-md="3">
                    <span class="d-block" style="padding-top: calc(0.438rem + 1px);">{{ item.type }}</span>
                  </b-form-group>
                  <validation-provider
                    name="Amount"
                    v-slot="validationContext"
                    :rules="{ required: true, double:true }"
                  >
                    <b-form-group label="Amount" label-for="h-amount" label-cols-md="3">
                      <b-form-input
                        v-model="item.amount"
                        :state="getValidationState(validationContext)"
                        placeholder="Amount"
                      />
                      <b-form-invalid-feedback
                        id="input-category-name-feedback"
                      >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </template>
          </b-form>
        </validation-observer>
      </b-card>
    </section>

    <action-buttons
      update-button
      back-button
      @update="update"
      :is-updating="isUpdating"
      :is-deleting="isDeleting"
    />
  </div>
</template>

<script>
import service from "../service";
import missionRewardService from "./../../mission_reward/service";
import { selectRewards, MISSION_KEYS, DEFAULT_MISSION_REWARDS, USER_TYPES } from "./../constants";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      isUpdating: false,
      isDeleting: false,
      eventMission: null,
      missionRewards: [],
      isChangedMissionKey: 0,
    };
  },

  computed: {
    eventMissionId() {
      return this.$route.params.event_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Event Mission List",
          to: { name: "mission-event-list" },
        },
      ];
      items.push({ text: "Event Mission Detail", active: true });
      return items;
    },
    isWatchAdsMission() {
      return this.eventMission.mission_key === MISSION_KEYS.WATCH_ADS
    }
  },

  watch: {
    'eventMission.mission_key'(newValue) {
      if (newValue === MISSION_KEYS.WATCH_ADS) {
        this.eventMission.rewards = null
        this.isChangedMissionKey = 1
      } else if (this.isChangedMissionKey) {
        this.eventMission.rewards = JSON.parse(JSON.stringify(DEFAULT_MISSION_REWARDS))
        this.isChangedMissionKey = 0
      }
    }
  },

  async created() {
    this.getEventMission();
    this.getMissionRewards();
  },

  methods: {
    async getMissionRewards() {
      const { data } = await missionRewardService.getAll();
      if (data && data.data.list && data.data.list.length) {
        this.missionRewards = data.data.list.map((reward) => {
          return {
            text: reward.type,
            value: reward.type,
          };
        });
        return;
      }

      this.missionRewards = selectRewards;
    },

    async getEventMission() {
      this.eventMission = await service.get({ id: this.eventMissionId });
    },

    getUserType(user_type) {
      return Object.keys(USER_TYPES)[user_type - 1] ?? ''
    },

    async update() {
      let validation = await this.$refs.updateForm.validate();
      if (validation) {
        this.isUpdating = true;
        if (this.isWatchAdsMission) {
          this.eventMission
        }

        await service.update({
          data: JSON.stringify(this.eventMission),
        });
        this.isUpdating = false;
      }
    },

    addMissionReward() {
      this.eventMission.rewards.push({
        type: '',
        amount: 0
      })
    },

    confirmDeleteMissionReward(index) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteMissionReward(index);
          }
        });
    },

    deleteMissionReward(index) {
      let newRewards = []
      let i = 0
      for (let reward of this.eventMission.rewards) {
        if (i++ !== index) {
          newRewards.push(reward)
        }
      }
      this.eventMission.rewards = newRewards
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style lang="scss" scoped>
.add-mission-reward {
  width: 20px;
  height: 20px;
  color: #207a1f;
  display: flex;
  float: right;
}
.remove-mission-reward {
  width: 20px;
  height: 20px;
  color: #cf2020;
}
</style>